import { FC } from "react";
import { Box } from "@mui/material";
import SubHeader from "../../components/common/SubHeader/SubHeader";
import SwitchContainerPayment from "../../components/Home/SwitchContainerPayment/SwitchContainerPayment";

const Home: FC = () => (
  <Box>
    <SubHeader text="Pagos" />
    <SwitchContainerPayment />
  </Box>
);
export default Home;
