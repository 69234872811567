import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { ChevronRight, ErrorOutline } from "@mui/icons-material";
import { paymentSummaryStyles } from "./PaymentSummary.styles";
import { usePaymentSummaryState } from "./state/usePaymentSummaryState";
import { formatNumber } from "../../../../shared/utils/formatNumber/format_number";

const PaymentSummary: FC = () => {
  const {
    availabilityData,
    rows,
    totalAmount,
    handlers: { goToPay, makeAnotherRequest },
  } = usePaymentSummaryState();

  return (
    <Box sx={paymentSummaryStyles.container}>
      <Typography variant="h5" sx={paymentSummaryStyles.title}>
        La deuda total corresponde al monto de:
      </Typography>

      <Typography variant="h3" sx={paymentSummaryStyles.amount}>
        ${formatNumber(totalAmount)}
      </Typography>

      <Box sx={paymentSummaryStyles.tableContainer}>
        <Table>
          <TableBody>
            {rows.map(([title, description], index) => (
              <TableRow key={index} sx={paymentSummaryStyles.row}>
                <TableCell sx={paymentSummaryStyles.cell}>{title}</TableCell>
                <TableCell sx={paymentSummaryStyles.cell}>
                  {description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {!availabilityData.availability && (
        <Box sx={paymentSummaryStyles.messageContainer}>
          <ErrorOutline sx={paymentSummaryStyles.iconMessage} />

          <Typography sx={paymentSummaryStyles.message}>
            {availabilityData.message}
          </Typography>
        </Box>
      )}

      <Box sx={paymentSummaryStyles.buttonsContainer}>
        <Button
          variant="contained"
          color="secondary"
          sx={paymentSummaryStyles.buttonBack}
          onClick={makeAnotherRequest}
        >
          Nueva Solicitud
        </Button>

        <Button
          variant="contained"
          color="primary"
          endIcon={<ChevronRight />}
          onClick={goToPay}
          disabled={!availabilityData.availability}
          sx={paymentSummaryStyles.buttonNext}
        >
          Ir a Pagar
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSummary;
