import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import dynamic from "next/dynamic";
import { FC, memo, useCallback } from "react";
import { SelectorComponent } from "../../common/Selector/Navigator/Navigator.interfaces";
import Selector from "../../common/Selector/Selector";
import { paymentServicesStyles as styles } from "./PaymentServices.styles";
import { useKshRedux } from "../../../store/hooks/hooks";

const CitationsService = dynamic(
  () => import("./CitationsService/CitationsService")
);
const RequestsService = dynamic(
  () => import("./RequestsService/RequestsService")
);
const AgreementsService = dynamic(
  () => import("./AgreementsService/AgreementsService")
);
const ServiceChoices = dynamic(() => import("./ServiceChoices/ServiceChoices"));

const PaymentServices: FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const {
    actions: { handlerConsult },
  } = useKshRedux();

  const selectorComponent = useCallback<SelectorComponent>(
    (currentScreenName, showScreen) => (
      <ServiceChoices
        data-testid="choices"
        selectedScreenName={isDesktop ? currentScreenName : undefined}
        onCitationsClick={() => showScreen("citations")}
        onRequestsClick={() => showScreen("requests")}
        onAgreementsClick={() => showScreen("agreements")}
      />
    ),
    [isDesktop]
  );

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Typography variant="h5" sx={styles.title}>
          ¿Tienes valores por pagar? Realízalos aquí
        </Typography>

        <Typography variant="subtitle1" sx={styles.subtitle}>
          Selecciona una de las siguientes opciones disponibles
        </Typography>

        <Selector.Navigator
          containerSx={styles.navigatorContainer}
          childrenWrapperSx={styles.navigatorChildren}
          initialScreen="citations"
          selector={selectorComponent}
          isDesktop={isDesktop}
        >
          <Selector.Screen name="citations">
            <CitationsService
              onValidSubmit={handlerConsult}
              isDesktop={isDesktop}
            />
          </Selector.Screen>

          <Selector.Screen name="requests">
            <RequestsService onValidSubmit={handlerConsult} />
          </Selector.Screen>

          <Selector.Screen name="agreements">
            <AgreementsService onValidSubmit={handlerConsult} />
          </Selector.Screen>
        </Selector.Navigator>
      </Box>
    </Box>
  );
};

export default memo(PaymentServices);
