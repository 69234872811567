import { RootState } from "../store.interfaces";

const selectAvailabilityData = (state: RootState) =>
  state.app.availabilityResponse;
const selectServiceRequest = (state: RootState) =>
  state.app.queryServiceRequestANT;
const selectServiceResponse = (state: RootState) =>
  state.app.queryServiceResponseANT;
const selectServiceResponseError = (state: RootState) =>
  state.app.queryServiceResponseErrorANT;
const selectKushkiCommission = (state: RootState) =>
  state.app.commission?.amount.subtotalIva ?? 0;
const selectCommissionIva = (state: RootState) =>
  state.app.commission?.amount.iva ?? 0;
const selectCommissionTotal = (state: RootState) =>
  state.app.commission?.totalAmount ?? 0;

export {
  selectAvailabilityData,
  selectCommissionTotal,
  selectCommissionIva,
  selectKushkiCommission,
  selectServiceRequest,
  selectServiceResponse,
  selectServiceResponseError,
};
