import { createNamedStyles } from "../../../shared/utils";

export const paymentServicesStyles = createNamedStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    maxWidth: ({ breakpoints }) => breakpoints.values.xl,
    minHeight: "552px",
    width: "100%",
    mt: 6,
    px: { xs: 4, md: 20, xl: 40 },
  },
  title: {
    color: "text.dark",
    textAlign: { xs: "center", md: "initial" },
    fontWeight: "400",
    fontSize: { xs: "27px", md: "32px" },
  },
  subtitle: {
    color: "text.grey",
    textAlign: { xs: "center", md: "initial" },
    fontWeight: "400",
    mt: 2,
  },
  navigatorContainer: {
    mt: { xs: 7.5, md: 3 },
  },
  navigatorChildren: {
    mt: { xs: 0, md: 3 },
  },
});
