import { RootState } from "../../../../store/store.interfaces";

export const selectTransaction = (state: RootState) =>
  state.checkout.transaction;

export const selectApplicant = (state: RootState) =>
  state.checkout.transaction.applicant;

export const selectBilling = (state: RootState) =>
  state.checkout.transaction.billing;

export const selectPaying = (state: RootState) =>
  state.checkout.transaction.paying;

export const selectCheckoutLoading = (state: RootState) =>
  state.checkout.isCheckoutLoading;

export const selectPaymentInfo = (state: RootState) =>
  state.checkout.transaction.payment;

export const selectCheckout = (state: RootState) => state.checkout;

export const selectPaymentLoading = (state: RootState) =>
  state.checkout.isPaymentLoading;

export const selectPaymentError = (state: RootState) =>
  state.checkout.paymentErrorMessage;

export const selectPaymentErrorTitle = (state: RootState) =>
  state.checkout.paymentErrorTitle;

export const selectPaymentErrorSubtitle = (state: RootState) =>
  state.checkout.paymentErrorSubtitle;

export const selectPaymentErrorSuggest = (state: RootState) =>
  state.checkout.paymentErrorSuggest;

export const selectPaymentSuccess = (state: RootState) =>
  state.checkout.isPaymentSuccess;

export const selectErrorCode = (state: RootState) => state.checkout.errorCode;

export const selectInitState = (state: RootState) => state.checkout.initState;
