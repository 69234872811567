import { CircularProgress, Paper, Typography } from "@mui/material";
import { ILoadingPage } from "./LoaingPage.interfaces";
import { loadingStyles } from "./LoadingPage.styles";

const LoadingPage = ({ title }: ILoadingPage): JSX.Element => (
  <Paper sx={loadingStyles.containerPaper}>
    <CircularProgress size={88} color="secondary" />
    <Typography sx={loadingStyles.title} color="text.grey">
      {title}
    </Typography>
    <Typography color="text.grey">Espera un momento.</Typography>
  </Paper>
);

export default LoadingPage;
