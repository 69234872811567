import get from "lodash/get";
import { SvgIconProps } from "@mui/material";
import { FC } from "react";
import { batch } from "react-redux";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/hooks";
import {
  selectServiceRequest,
  selectServiceResponse,
  selectServiceResponseError,
} from "../../../../../store/selectors/selectors";
import { IUsePaymentErrorState } from "./usePaymentErrorState.interfaces";
import {
  ANTTitleEnum,
  CustomSuggest,
  CustomSuggestMessage,
  ErrorCode,
  ERRORS_ANT,
} from "../../../../../shared/enums/errorEnum";
import { ConnectionErrorIcon } from "../../../../../assets/icons/ErrorIcons";
import {
  setCommission,
  setCurrentContainer,
  setQueryServiceRequestANT,
  setQueryServiceResponseANT,
  setQueryServiceResponseErrorANT,
} from "../../../../../store/actions/actions";
import { PaymentContainerEnum } from "../../enums/payment_containers";
import { TRows } from "../../PaymentSummary/state/usePaymentSummaryState.interfaces";
import { TitleRowsEnum } from "../../PaymentSummary/enums/title_rows";

enum ErrorMessages {
  NO_PENDING_BALANCE = "No tienes saldo pendiente",
  DEFAULT = "Presentamos problemas para realizar tu consulta",
}

const ORDER_MESSAGE: Record<string, string> = {
  SOL: "Órdenes de pago / Solicitud de servicio",
  CDP: "Convenios de pago",
  CIT: "Citaciones / Infracciones",
};
export const usePaymentErrorState = (): IUsePaymentErrorState => {
  const dispatch = useAppDispatch();
  const responseError = useAppSelector(selectServiceResponseError);
  const request = useAppSelector(selectServiceRequest);
  const responseANT = useAppSelector(selectServiceResponse);

  const response = responseError ?? responseANT;
  const errorField = responseError ? "code" : "codError";
  const title = responseError
    ? get(response, "title", ANTTitleEnum.OCURRIO_UN_ERROR)
    : "¡Felicitaciones!";

  const messageAnt = ERRORS_ANT[<ErrorCode>get(response, errorField)];
  const identification = get(response, "identification");
  const code: ErrorCode = get(response, errorField, ErrorCode.G9999);
  const procedureName: string = get(response, "procedureName");
  let message: string = get(response, "message", ErrorMessages.DEFAULT);
  if (ErrorCode.T0000 === code) message = ErrorMessages.NO_PENDING_BALANCE;

  const suggestion: string = CustomSuggest.includes(code)
    ? CustomSuggestMessage[code]
    : get(response, "suggest");

  const Icon: FC<SvgIconProps> = get(messageAnt, "icon", ConnectionErrorIcon);
  const date: Date = new Date();
  const time: string = `${date.getHours()}:${date.getMinutes()}`;
  const fullDate: string = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${time}`;

  const isCongrats: boolean = [
    ErrorCode.T8013,
    ErrorCode.T0031,
    ErrorCode.T0000,
  ].includes(<ErrorCode>code);
  const rows: TRows = [
    [TitleRowsEnum.ORDER_TYPE, ORDER_MESSAGE[procedureName]],
    [TitleRowsEnum.IDENTIFICATION_NUMBER, identification],
  ];

  if (request?.vehiclePlate) {
    rows.push([TitleRowsEnum.PLATE_NUMBER, request?.vehiclePlate]);
  }

  rows.push([TitleRowsEnum.DATE_TIME, fullDate]);

  const makeAnotherRequest = () => {
    batch(() => {
      dispatch(setCommission());
      dispatch(setQueryServiceRequestANT());
      dispatch(setCurrentContainer(PaymentContainerEnum.PAYMENT));
      dispatch(setQueryServiceResponseANT());
      dispatch(setQueryServiceResponseErrorANT());
    });
  };

  return {
    ErrorIcon: Icon,
    message,
    isCongrats,
    rows,
    suggestion,
    title,
    handlers: { makeAnotherRequest },
  };
};
