import { FC } from "react";
import { Box } from "@mui/material";
import LoadingPage from "../../../Checkout/LoadingPage/LoadingPage";
import { paymentLoadingStyles } from "./PaymentLoading.styles";

const PaymentLoading: FC = () => (
  <Box sx={paymentLoadingStyles.container}>
    <LoadingPage title="Estamos procesando tu consulta." />
  </Box>
);

export default PaymentLoading;
