import { Box, Button, Typography } from "@mui/material";
import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  useMemo,
  useState,
} from "react";
import isScreenProps from "../util/isScreenProps";
import { NavigatorProps } from "./Navigator.interfaces";
import { navigatorStyles } from "./Navigator.styles";

const Navigator: FC<NavigatorProps> = ({
  initialScreen,
  selector,
  isDesktop = true,
  children,
  containerSx,
  childrenWrapperSx,
}) => {
  const [screenName, setScreenName] = useState(initialScreen);
  const [showSelector, setShowSelector] = useState(true);

  const emptyElement = useMemo(
    () => (
      <Typography color="text.dark" variant="subtitle1">
        No element
      </Typography>
    ),
    []
  );

  const element = Children.toArray(children).find((child) => {
    if (!isValidElement(child)) {
      return false;
    }

    const childProps = child.props;

    if (!isScreenProps(childProps)) {
      return false;
    }

    return childProps.name === screenName;
  });

  const screen = isValidElement(element) ? cloneElement(element) : emptyElement;

  const showScreen = (name: string) => {
    setScreenName(name);
    setShowSelector(false);
  };

  const desktopStyle = isDesktop
    ? undefined
    : { display: showSelector ? "none" : "block" };

  const selectorDesktopStyle = isDesktop
    ? undefined
    : { display: showSelector ? "block" : "none" };

  return (
    <Box sx={containerSx}>
      <Box sx={selectorDesktopStyle}>{selector(screenName, showScreen)}</Box>

      <Box sx={{ ...childrenWrapperSx, ...desktopStyle }}>{screen}</Box>

      {!isDesktop && !showSelector && (
        <Box sx={navigatorStyles.backButton}>
          <Button
            size="medium"
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => setShowSelector(true)}
          >
            Regresar
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Navigator;
