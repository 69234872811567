import SectionsPayment from "../../../shared/constants/containers_payment";
import { useKshRedux } from "../../../store/hooks/hooks";
import { PaymentContainerEnum } from "../PaymentServices/enums/payment_containers";

const SwitchContainerPayment = () => {
  const { store } = useKshRedux();

  const Content =
    SectionsPayment[store.currentContainer ?? PaymentContainerEnum.PAYMENT];

  return <Content />;
};

export default SwitchContainerPayment;
