export const loadingStyles = {
  containerPaper: {
    width: { xs: "90%", md: 727 },
    height: { xs: "50%", md: 528 },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "172px 55px",
    textAlign: "center",
  },
  title: {
    marginTop: 3,
  },
} as const;
