import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ISubHeaderProps } from "./SubHeader.interfaces";
import { subHeaderStyles } from "./SubHeader.styles";

const SubHeader: FC<ISubHeaderProps> = ({ text }) => (
  <Box sx={subHeaderStyles.container}>
    <Typography sx={subHeaderStyles.text}>{text}</Typography>
  </Box>
);

export default SubHeader;
