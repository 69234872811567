import { useRouter } from "next/router";
import { batch } from "react-redux";
import {
  IUsePaymentSummaryState,
  TRows,
} from "./usePaymentSummaryState.interfaces";
import {
  InternalRoutesEnum,
  ServiceTypeEnum,
} from "../../../../../shared/enums";
import { IQueryParamTransaction } from "../../../../../shared/interfaces/QueryParams.interfaces";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/hooks/hooks";
import { ServicesForm } from "../../PaymentServices.interfaces";
import { TitleRowsEnum } from "../enums/title_rows";
import { ApplicationSourcesEnum } from "../../../../../shared/enums/application_sources";
import {
  setCommission,
  setCurrentContainer,
  setQueryServiceRequestANT,
  setQueryServiceResponseANT,
  setQueryServiceResponseErrorANT,
} from "../../../../../store/actions/actions";
import { PaymentContainerEnum } from "../../enums/payment_containers";
import {
  selectAvailabilityData,
  selectCommissionIva,
  selectCommissionTotal,
  selectKushkiCommission,
  selectServiceRequest,
  selectServiceResponse,
} from "../../../../../store/selectors/selectors";

const getAdditionalRow = ({
  serviceType,
  vehiclePlate = "",
  orderNumber = "",
}: ServicesForm): TRows => {
  const plate: TRows = [[TitleRowsEnum.PLATE, vehiclePlate]];
  const payNumber: TRows = [[TitleRowsEnum.ORDER_NUMBER, orderNumber]];
  const emptyRow: TRows = [];

  const possibleRows: Record<ServiceTypeEnum, TRows> = {
    [ServiceTypeEnum.CIT_ID]: emptyRow,
    [ServiceTypeEnum.CIT_PLATE]: plate,
    [ServiceTypeEnum.CDP]: emptyRow,
    [ServiceTypeEnum.SOL]: payNumber,
  };

  return possibleRows[serviceType as keyof typeof ServiceTypeEnum];
};

export const usePaymentSummaryState = (): IUsePaymentSummaryState => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const query = useAppSelector(selectServiceRequest);
  const response = useAppSelector(selectServiceResponse);
  const kushkiCommission = useAppSelector(selectKushkiCommission);
  const commissionTotal = useAppSelector(selectCommissionTotal);
  const iva = useAppSelector(selectCommissionIva);
  const availabilityData = useAppSelector(selectAvailabilityData)!;

  const amount = response?.value ?? 0;
  const totalAmount = amount + commissionTotal;

  const name = response?.name ?? "";
  const documentType = response?.typeIdentification!;

  const documentNumber = response?.identification ?? "";
  const vehiclePlateNumber = query?.vehiclePlate;
  const procedureName = response?.procedureName!;
  const applicationSource = ApplicationSourcesEnum.SELF;
  const serviceType = query?.serviceType!;
  const orderNumber = query?.orderNumber!;

  const rows: TRows = [
    [TitleRowsEnum.NAMES, name],
    [TitleRowsEnum.IDENTIFICATION_TYPE, documentType],
    [TitleRowsEnum.IDENTIFICATION_NUMBER, documentNumber],
    ...getAdditionalRow(query!),
  ];

  const goToPay = () => {
    const queryParamTransaction: IQueryParamTransaction = {
      name,
      documentType,
      documentNumber,
      vehiclePlateNumber,
      amount,
      applicationSource,
      kushkiCommission,
      procedureName,
      iva,
      orderNumber,
      serviceType,
      totalAmount,
    };

    router.push(
      `${InternalRoutesEnum.CHECKOUT}/?transaction=${btoa(
        unescape(encodeURIComponent(JSON.stringify(queryParamTransaction)))
      )}`
    );
  };

  const makeAnotherRequest = () => {
    batch(() => {
      dispatch(setCommission());
      dispatch(setQueryServiceRequestANT());
      dispatch(setCurrentContainer(PaymentContainerEnum.PAYMENT));
      dispatch(setQueryServiceResponseANT());
      dispatch(setQueryServiceResponseErrorANT());
    });
  };

  return {
    availabilityData,
    totalAmount,
    rows,
    handlers: { goToPay, makeAnotherRequest },
  };
};
