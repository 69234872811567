import { StepPage } from "../enums/step_page";
import { IStep } from "../interfaces/Step.interfaces";

export const STEPS_ARRAY: IStep[] = [
  {
    name: StepPage.PAY,
  },
  {
    name: StepPage.BILLING,
  },
  {
    name: StepPage.PAYMENT_SUMMARY,
  },
];
