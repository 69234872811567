export const REQUIRED_MESSAGE: string = "*Este campo es obligatorio";
export const REQUIRED_FIELD: string = "Este campo es requerido";
export const INVALID_NUMBER_ONLY: string = "Ingresa un valor númerico";
export const NO_DEBIT_CARD_ALLOWED: string =
  "El número ingresado es de una tarjeta débito";
export const PRELOADED_MESSAGE: string =
  "Se han precargado los datos de ciertos campos para agilizar el proceso, si necesitas facturar a nombre de otra persona puedes editar los datos.";
export const INVALID_EMAIL: string = "Correo Inválido";
export const INVALID_PHONE: string = "Teléfono Inválido";
export const ERROR_HAS_HAPPENED: string = "Ocurrió un error";
export const PAYMENT_ISSUES: string =
  "Presentamos problemas para realizar tu consulta, por favor inténtalo más tarde.";
export const GO_BACK_LABEL: string = "Volver a inicio";
export const NEW_REQUEST: string = "Nueva Solicitud";
