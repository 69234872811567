export enum TitleRowsEnum {
  NAMES = "Nombres:",
  IDENTIFICATION_TYPE = "Tipo de identificación:",
  IDENTIFICATION_NUMBER = "Número de identificación:",
  PLATE = "Placa:",
  PLATE_NUMBER = "Número de placa:",
  ORDER_NUMBER = "Orden de pago:",
  ORDER_TYPE = "Tipo de trámite:",
  DATE_TIME = "Fecha y hora de consulta:",
}
