import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { GO_BACK_LABEL, NEW_REQUEST } from "../../../../shared/constants";
import { errorStyles as styles } from "./PaymentError.styles";
import { usePaymentErrorState } from "./state/usePaymentErrorState";
import { ANTTitleEnum } from "../../../../shared/enums/errorEnum";

export type PaymentErrorProps = {};

const PaymentError: FC<PaymentErrorProps> = () => {
  const {
    ErrorIcon,
    isCongrats,
    message,
    rows,
    suggestion,
    title,
    handlers: { makeAnotherRequest },
  } = usePaymentErrorState();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={styles.content}>
      <Paper sx={styles.paper}>
        <ErrorIcon sx={styles.icon} />
        <Box sx={styles.container}>
          <Typography
            sx={styles.header}
            color={
              title === ANTTitleEnum.ALGO_SALIO_MAL ||
              title === ANTTitleEnum.OCURRIO_UN_ERROR
                ? "text.error"
                : "text.blue"
            }
          >
            {title}
          </Typography>
          <Typography sx={styles.text}>{message}</Typography>
          <Typography sx={styles.text}>{suggestion}</Typography>

          {isCongrats && (
            <Box sx={styles.tableContainer}>
              <Table>
                <TableBody>
                  {rows.map(([rowTitle, description], index) => (
                    <TableRow key={index} sx={styles.row}>
                      <TableCell sx={styles.cell}>{rowTitle}</TableCell>
                      <TableCell sx={styles.cell}>{description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}

          <Button
            fullWidth={!isDesktop}
            sx={styles.button}
            variant="contained"
            color="secondary"
            onClick={makeAnotherRequest}
          >
            {!isCongrats && title !== ANTTitleEnum.OCURRIO_UN_ERROR
              ? GO_BACK_LABEL
              : NEW_REQUEST}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default PaymentError;
