import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../actions/actions";
import { STEPS_ARRAY } from "../../shared/constants";
import { AppDispatch, RootState } from "../store.interfaces";
import { consultPayment } from "../epics/consultPaymentEpic/consult_payment_epic";
import { ServicesForm } from "../../components/Home/PaymentServices/PaymentServices.interfaces";
import { selectPaymentLoading } from "../../components/Checkout/store/selectors/selectors";
import { AppState } from "../interfaces/AppState.interfaces";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useKshRedux = () => {
  const dispatch = useAppDispatch();
  const store: AppState = useAppSelector((state: RootState) => state.app);
  const isPaymentLoading = useSelector(selectPaymentLoading);

  const goNextStep = () => {
    const { currentStep } = store;

    if (currentStep === STEPS_ARRAY.length - 2) {
      dispatch(setCurrentStep(currentStep + 2));
    } else if (currentStep < STEPS_ARRAY.length) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const goBackStep = () => {
    const { currentStep } = store;
    if (currentStep > 0 && !isPaymentLoading) {
      dispatch(setCurrentStep(currentStep - 1));
    }
  };

  const handlerConsult = (dataForm: ServicesForm) => {
    dispatch(consultPayment(dataForm));
  };

  return {
    actions: {
      goNextStep,
      goBackStep,
      handlerConsult,
    },
    store,
  };
};
