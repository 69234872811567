import { FC } from "react";
import PaymentServices from "../../components/Home/PaymentServices/PaymentServices";
import { CurrentContainerType } from "../../store/interfaces/AppState.interfaces";
import PaymentSummary from "../../components/Home/PaymentServices/PaymentSummary/PaymentSummary";
import PaymentLoading from "../../components/Home/PaymentServices/PaymentLoading/PaymentLoading";
import PaymentError from "../../components/Home/PaymentServices/PaymentError/PaymentError";

const SectionsPayment: {
  [K in CurrentContainerType]: (() => JSX.Element) | FC;
} = {
  payment: PaymentServices,
  summary: PaymentSummary,
  loading: PaymentLoading,
  error: PaymentError,
};

export default SectionsPayment;
