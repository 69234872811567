import { createNamedStyles } from "../../../shared/utils";

export const SUBHEADER_CONTAINER_HEIGHT_SCALE = 13;

export const subHeaderStyles = createNamedStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    paddingY: "32px",
  },
  text: {
    width: "100%",
    maxWidth: ({ breakpoints }) => breakpoints.values.xl,
    color: "text.dark",
    typography: "h5",
    transition: "all 0.5s",
    fontWeight: "400",
    textAlign: "left",
  },
});
