import { createNamedStyles } from "../../../../shared/utils";

export const errorStyles = createNamedStyles({
  button: {
    fontWeight: 500,
    mb: "32px",
    mt: "24px",
  },
  container: {
    alignItems: "center",
    textAlign: "center",
  },
  item: {
    width: "100%",
  },
  content: {
    maxWidth: ({ breakpoints }) => breakpoints.values.xl,
    minHeight: "360px",
    mt: 6,
    px: {
      lg: 40,
      md: 20,
      xs: 4,
    },
    width: "100%",
  },
  header: {
    fontFamily: "Poppins",
    fontWeight: 400,
    typography: "h5",
  },
  icon: {
    mb: "24px",
    mt: {
      md: "32px",
      xs: "0px",
    },
  },
  paper: {
    alignItems: "center",
    background: {
      md: "#FFFFFF",
      xs: "transparent",
    },
    boxShadow: {
      md: "0px 1px 4px rgb(82 89 103 / 8%), 0px 0px 0px rgb(82 89 103 / 10%), 0px 1px 2px rgb(82 89 103 / 20%)",
      xs: 0,
    },
    display: "flex",
    flexDirection: "column",
    m: "auto",
    width: "100%",
    marginTop: "24px",
  },
  text: {
    color: "text.grey",
    fontFamily: "Roboto",
    mt: "8px",
    typography: "body1",
    width: {
      md: "500px",
    },
  },
  title: {
    color: "text.dark",
    textAlign: { xs: "center", md: "initial" },
    fontWeight: "400",
    fontSize: { xs: "27px", md: "32px" },
  },
  subtitle: {
    color: "text.grey",
    textAlign: { xs: "center", md: "initial" },
    fontWeight: "400",
    mt: 2,
  },
  tableContainer: {
    overflowX: "auto",
    m: ({ spacing }) => spacing(3, "auto", 0, "auto"),
    maxWidth: ({ spacing }) => spacing(81),
  },
  row: {
    bgcolor: "background.default",

    "&:nth-of-type(odd)": {
      bgcolor: "background.paper3",
    },

    "&>td:last-child ": {
      textAlign: "right",
    },
  },
  cell: {
    p: ({ spacing }) => spacing(1.7, 2),
    borderBottom: "1px solid",
    borderColor: "primary.light2",
    fontSize: "14px",
    fontFamily: "Roboto",
    color: "text.dark",
  },
});
