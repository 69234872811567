import { SxProps, Theme } from "@mui/material";
import { createNamedStyles } from "../../../../shared/utils";

const PADDING_X_BUTTON_SCALE = 2;

const buttons: SxProps<Theme> = {
  m: 1,
  minWidth: ({ spacing }) => spacing(21.5),
  width: ({ spacing }) => ({
    md: "auto",
    xs: `calc(100% - ${spacing(PADDING_X_BUTTON_SCALE)})`,
  }),
  px: PADDING_X_BUTTON_SCALE,
};

export const paymentSummaryStyles = createNamedStyles({
  container: {
    border: { md: "1px solid", xs: "none" },
    borderColor: { md: "primary.light3" },
    bgcolor: { md: "background.paper" },
    p: ({ spacing }) => spacing(4, 4, 5.75, 4),
    m: ({ spacing }) => spacing(6, "auto", 10, "auto"),
    maxWidth: ({ spacing }) => spacing(91),
  },
  title: {
    textAlign: "center",
    fontWeight: "normal",
    color: "text.dark",
  },
  amount: {
    textAlign: "center",
    mt: 3,
    fontWeight: "normal",
    color: "text.blue",
  },
  tableContainer: {
    overflowX: "auto",
    m: ({ spacing }) => spacing(3, "auto", 0, "auto"),
    maxWidth: ({ spacing }) => spacing(73),
  },
  messageContainer: {
    bgcolor: "secondary.light3",
    display: "flex",
    alignItems: "center",
    p: 2,
    width: "100%",
    borderRadius: 2,
    m: ({ spacing }) => spacing(3, "auto", 1, "auto"),
    maxWidth: ({ spacing }) => spacing(73),
  },
  row: {
    bgcolor: "background.default",

    "&:nth-of-type(odd)": {
      bgcolor: "background.paper3",
    },

    "&>td:last-child ": {
      textAlign: "right",
    },
  },
  cell: {
    p: ({ spacing }) => spacing(1.7, 2),
    borderBottom: "1px solid",
    borderColor: "primary.light2",
    fontSize: "14px",
    fontFamily: "Roboto",
    color: "text.dark",
  },
  buttonsContainer: {
    mt: 3.75,
    display: "flex",
    justifyContent: "center",
    mx: "auto",
    flexDirection: { xs: "column", md: "row" },
    maxWidth: ({ spacing }) => ({ xs: spacing(70), md: "none" }),
  },
  buttonNext: {
    ...buttons,
    order: { xs: 1, md: 2 },
  },
  buttonBack: {
    ...buttons,
    order: { xs: 2, md: 1 },
  },
  message: {
    typography: "subtitle1",
    color: "secondary.dark2",
    lineHeight: "140%",
  },
  iconMessage: {
    width: ({ spacing }) => spacing(3.3),
    height: "auto",
    color: "secondary.dark2",
    mr: 2,
  },
});
